import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Footer from './Components/Footer.js'
import Header from './Components/Header.js'
import RouterX from './routerg/Routerx.js';



function App() {
  return (
    <div>
      <RouterX/>
      <Footer/>
    </div>
  );
}

export default App;
