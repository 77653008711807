import React from "react";


const TechnologyNews=()=> {



    return(
        <>
        Hello Technology News!
        </>
    )
}

export default TechnologyNews;