import React from "react";


const PoliticsNews=()=> {



    return(
        <>
        Hello Politics News!
        </>
    )
}

export default PoliticsNews;