// src/Components/subComponents/CoNav/CoNav.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CoNav.css';

const CoNav = () => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);

    const navItems = [
        { label: 'World', path: '/world', icon: 'fa-globe' },
        { label: 'India', path: '/india', icon: 'fa-solid fa-earth-asia' },
        { label: 'Technology', path: '/technology', icon: 'fa-microchip' },
        { label: 'Health', path: '/health', icon: 'fa-heartbeat' },
        { label: 'Countries', path: '/countries', icon: 'fa-map' },
        { label: 'Business', path: '/business', icon: 'fa-briefcase' },
        { label: 'Environment', path: '/environment', icon: 'fa-leaf' },
        { label: 'Sports', path: '/sports', icon: 'fa-futbol' },
        { label: 'Politics', path: '/politics', icon: 'fa-balance-scale' },
    ];

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % navItems.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + navItems.length) % navItems.length);
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <nav className="co-nav">
            <button className="co-nav__button" onClick={prevSlide} aria-label="Previous">
                <i className="fas fa-chevron-left"></i>
            </button>
            <div className="co-nav__slider-container">
                <div 
                    className="co-nav__slider" 
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {navItems.map((item, index) => (
                        <div
                            className={`co-nav__item ${index === currentIndex ? 'active' : ''}`}
                            key={index}
                        >
                            <button 
                                className="co-nav__link" 
                                onClick={() => handleNavigation(item.path)}
                                aria-label={item.label}
                            >
                                <i className={`fas ${item.icon} co-nav__icon`}></i>
                                <span className="co-nav__label">{item.label}</span>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <button className="co-nav__button" onClick={nextSlide} aria-label="Next">
                <i className="fas fa-chevron-right"></i>
            </button>
        </nav>
    );
};

export default CoNav;
