import React from "react";


const InternationalNews=()=> {



    return(
        <>
        Hello International News!
        </>
    )
}

export default InternationalNews;