import React, { useState } from 'react';
import './carousal.css';

const MobileCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    'https://via.placeholder.com/600x300?text=Slide+1',
    'https://via.placeholder.com/600x300?text=Slide+2',
    'https://via.placeholder.com/600x300?text=Slide+3'
  ];

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  return (
    <div className="mobile-carousel">
      <div className="mobile-carousel-inner" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {slides.map((src, index) => (
          <div className="mobile-carousel-item" key={index}>
            <img src={src} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      <button className="mobile-carousel-control prev" onClick={handlePrev}>‹</button>
      <button className="mobile-carousel-control next" onClick={handleNext}>›</button>
    </div>
  );
};

export default MobileCarousel;
