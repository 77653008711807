// src/Components/Home.js
import React from 'react';
import './Home.css';
import { Outlet, Link } from "react-router-dom"; 
import Carousel from './subComponents/carousal';
import Card from './subComponents/Cards/Card.js';
import CoNav from './subComponents/CoNav/CoNav.js';
import HeadManager from '../Components/HeadManager.js';

const Home = () => {
  return (
    <div className="home">
      <HeadManager 
        title="Welcome to Mobile Version of Workhelper Website!" 
        description="Tech News, Breaking News, Video Downloader, Converting PNG to PDF, JPG to PDF, Watching Live TV, General Utility Website for free for the people of the world." 
        canonicalUrl={window.location.href} 
      />
      <CoNav/>
      <Carousel/>

      <div className="home-content">
        <Outlet />
        <Card />
      </div>
    </div>
  );
};

export default Home;
