import React from "react";


const SportsNews=()=> {



    return(
        <>
        Hello Sports News!
        </>
    )
}

export default SportsNews;