import React from "react";


const BusinessNews=()=> {



    return(
        <>
        Hello Business News!
        </>
    )
}

export default BusinessNews;