import React from "react";


const WorldNews=()=> {



    return(
        <div>
        Hello World News!
        </div>
    )
}

export default WorldNews;