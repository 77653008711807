import React from 'react';
import './Converter.css'; // Make sure to create this CSS file
import { Outlet, Link } from "react-router-dom"; 
import HeadManager from '../Components/HeadManager';

const Converter = () => {
    return (
        <div className="converter-container">
            <HeadManager 
                title="Convert Files Effortlessly | PNG to PDF, JPG, SVG, and More | Online Converter" 
                description="Use our Converter to easily convert files between formats like PNG to PDF, PNG to JPG, JPEG to SVG, and more. Fast, free, and reliable—your all-in-one online file conversion tool." 
                canonicalUrl={window.location.href} 
            />

            <h1 className="converter-title">
                <i className="fas fa-exchange-alt"></i> File Converter
            </h1>
            
            <div className="converter-form">
                <input type="text" placeholder="Enter File URL" className="converter-input" />
                <select className="converter-select">
                    <option value="">Select Target Format</option>
                    <option value="pdf">PDF</option>
                    <option value="jpg">JPG</option>
                    <option value="png">PNG</option>
                    <option value="svg">SVG</option>
                    {/* Add more options as needed */}
                </select>
                <button className="converter-button">
                    <i className="fas fa-upload"></i> Convert
                </button>
            </div>
        </div>
    );
}

export default Converter;
