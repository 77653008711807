import React from 'react';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about">
          <h2>About Us</h2>
          <p>
            We are dedicated to providing the best service possible. Our team works tirelessly to ensure customer satisfaction.
          </p>
        </div>

        <div className="footer-section quick-links">
          <h2>Quick Links</h2>
          <ul>
            <li><a href="https://m.workhelper.shop/">Home</a></li>
            <li><a href="https://m.workhelper.shop/Services">Services</a></li>
            <li><a href="https://m.workhelper.shop/Downloader">Downloader</a></li>
            <li><a href="https://m.workhelper.shop/Converter">Converter</a></li>
          </ul>
        </div>

        <div className="footer-section social-media">
          <h2>Follow Us</h2>
          <div className="social-icons">
            <a href="#" aria-label="Facebook">Facebook</a>
            <a href="#" aria-label="Twitter">Twitter</a>
            <a href="#" aria-label="Instagram">Instagram</a>
            <a href="#" aria-label="LinkedIn">LinkedIn</a>
          </div>
        </div>

        <div className="footer-section newsletter">
          <h2>Subscribe to Our Newsletter</h2>
          <form>
            <input type="email" placeholder="Your Email" required />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Workhelper . All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
