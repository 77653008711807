import React from 'react';
import { Helmet } from 'react-helmet';

function HeadManager({ title, description, canonicalUrl }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
}

export default HeadManager;
