import React from "react";


const ScienceNews=()=> {



    return(
        <>
        Hello Science News!
        </>
    )
}

export default ScienceNews;