import React from "react";


const EnvironmentalNews=()=> {



    return(
        <>
        Hello Environmental News!
        </>
    )
}

export default EnvironmentalNews;