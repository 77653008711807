import React from 'react';
import './Home.css';
import { Outlet, Link } from "react-router-dom"; // Ensure you have some CSS for styling
import HeadManager from '../Components/HeadManager'; 


const Downloader = () => {

return (
    <div>
          <HeadManager 
    title="Download Videos and Images Easily | Social Media & YouTube Downloader" 
    description="Use our Downloader to easily download videos, images, and other media from social media platforms like Facebook, Instagram, Twitter, and YouTube. Fast, free, and user-friendly—your go-to tool for downloading content online." 
    canonicalUrl={window.location.href} 
  />
       
       <div class="row">
  <div class="col-4">
    <div class="p-card">
      <div class="p-card__content">
        <img class="p-card__image" alt="" height="185" width="330" src="https://assets.ubuntu.com/v1/36f1139e-Design-and-Web-Team-Blog.jpg"/>
        <h4>
          <a href="#">Open Source Robotics Challenges</a>
        </h4>
        <p class="u-no-padding--bottom">Open Source Robotics Challenges is a series of blogs...</p>
      </div>
    </div>
  </div>
</div>

    </div>
)

}

export default Downloader;