import React from 'react';
import './Home.css';
import { Outlet, Link } from "react-router-dom"; // Ensure you have some CSS for styling
 import HeadManager from '../Components/HeadManager';


const LiveTV = () => {

return (
    <div>
       <HeadManager 
        title="Watch Latest Movies, Songs, Dramas and  many more " 
        description="Experience the ultimate entertainment hub where you can catch the latest movies, trending songs, captivating dramas, and so much more—all live! Whether you're in the mood for a blockbuster film, a heartwarming drama, or the hottest music videos, our platform offers seamless access to a wide array of TV channels. Tune in anytime, anywhere, and enjoy endless entertainment right at your fingertips" 
        canonicalUrl={window.location.href} 
      />
        hello Live TV
    </div>
)

}

export default LiveTV;