import React from "react";


const healthNews=()=> {



    return(
        <>
        Hello health News!
        </>
    )
}

export default healthNews;