import React, { useState, useEffect } from "react";
import { database } from "../../../Components/DBConnect";
import { collection, query, getDocs, orderBy, limit, startAfter } from "firebase/firestore";
import './Cards.css';

const Card = () => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastVisible, setLastVisible] = useState(null);
    const postsPerPage = 10;

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const postsCollection = collection(database, 'Blogs_Contents');
                let q = query(postsCollection, orderBy("createdAt", "desc"), limit(postsPerPage));
                if (lastVisible) {
                    q = query(postsCollection, orderBy("createdAt", "desc"), startAfter(lastVisible), limit(postsPerPage));
                }
                const postsSnapshot = await getDocs(q);
                const postData = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPosts(postData);
                setLastVisible(postsSnapshot.docs[postsSnapshot.docs.length - 1]);
            } catch (error) {
                console.error("Error getting documents:", error);
            }
        };

        fetchPosts();
    }, [currentPage]);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    return (
        <div className="cards-container">
            {posts.map(post => (
                <div key={post.id} className="card">
                    <div className="card__content">
                        <h4 className="card__title">
                            <a href={`/post/${post.id}`} dangerouslySetInnerHTML={{ __html: post.title || 'Untitled' }} />
                        </h4>
                        <p className="card__description" dangerouslySetInnerHTML={{ __html: `${post.content.slice(0, 100)}...` }}></p>
                        {/* Time and Writer Details with Font Awesome Icons */}
                        <p className="card__meta">
                            <small>
                                <i className="fas fa-user" aria-hidden="true"></i> {post.WriterName || 'Unknown'}
                            </small>
                            <br />
                            <small>
                                <i className="fas fa-calendar-alt" aria-hidden="true"></i> {new Date(post.createdAt.seconds * 1000).toLocaleString()}
                            </small>
                        </p>
                    </div>
                </div>
            ))}
            {/* Pagination Buttons */}
            <div className="pagination-container">
                <button 
                    className="pagination-btn" 
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    <i className="fas fa-chevron-left" aria-hidden="true"></i> Previous
                </button>
                <button 
                    className="pagination-btn" 
                    onClick={handleNextPage}
                >
                    Next <i className="fas fa-chevron-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    );
};

export default Card;
