import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { database } from '../../Components/DBConnect.js'; // Ensure this points to your Firebase config
import HeadManager from '../../Components/HeadManager'; // Adjust the path based on where you store this file
import './PostDetails.css';

const PostDetails = () => {
    const { postId } = useParams(); // Extract postId from the URL
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true); // Track loading state

    useEffect(() => {
        const fetchPost = async () => {
            setLoading(true); // Set loading to true when fetching starts
            try {
                const postDoc = doc(database, 'Blogs_Contents', postId);
                const docSnap = await getDoc(postDoc);
                if (docSnap.exists()) {
                    setPost({
                        id: docSnap.id,
                        title: docSnap.data().title,
                        description: docSnap.data().description || '', // Default to empty string if undefined
                        content: docSnap.data().content,
                        createdAt: docSnap.data().createdAt,
                        WriterName: docSnap.data().WriterName || 'Unknown' // Capture WriterName if available
                    });
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching post:', error);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchPost();
    }, [postId]);

    const truncateDescription = (description = '', wordLimit = 70) => {
        const words = description.replace(/<[^>]+>/g, '').split(/\s+/);
        return words.slice(0, wordLimit).join(' ') + (words.length > wordLimit ? '...' : '');
    };

    return (
        <div>
            {post && (
                <HeadManager 
                    title={post.title.replace(/<[^>]+>/g, '')}  // Stripping HTML tags from title
                    description={truncateDescription(post.description)} // Truncate description
                    canonicalUrl={`https://m.workhelper.shop/post/${post.id}`} 
                />
            )}
            <div className="post-details p-card--highlighted u-align-text-center">
                {loading ? (
                    <div className="loading-spinner">
                        <i className="fas fa-spinner fa-spin" /> {/* Spinner icon */}
                    </div>
                ) : post ? (
                    <>
                        <h1 className="post-title p-heading--2" dangerouslySetInnerHTML={{ __html: post.title }} />
                        <div className="post-meta">
                            <p className="post-author"><i className="fas fa-user" /> {post.WriterName}</p>
                            <p className="post-date"><i className="fas fa-calendar-alt" /> {new Date(post.createdAt.seconds * 1000).toLocaleDateString()}</p>
                        </div>
                        <div className="post-content p-text--normal" dangerouslySetInnerHTML={{ __html: post.content }} />
                        <p className="post-comment"><i className="fas fa-comment" /> Comments (Coming Soon)</p>
                    </>
                ) : (
                    <p>No post found!</p>
                )}
            </div>
        </div>
    );
};

export default PostDetails;
