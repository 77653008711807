import React from "react";


const IndiaNews=()=> {



    return(
        <>
        Hello India News!
        </>
    )
}

export default IndiaNews;